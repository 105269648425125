<template>
  <div v-if="metadata != null">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
  </div>
</template>

<script>
import { CustomCgCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/takeUpBookService'

export default {
  components: { CustomCgCrudMetaView },
  data() {
    return {
      metadata: null,
      reloadData: false,
      crudService: service
    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      meta.actions = [
        {
          title: ({ node }) => {
            return `${helpers
              .getEnumValue(node.data.status)
              ?.toLocaleLowerCase()}` == 'available'
              ? this.$t(
                  'controlTower.pages.takeUpBook.messages.makeNotAvailable'
                )
              : this.$t('controlTower.pages.takeUpBook.messages.makeAvailable')
          },
          className: ({ node }) => {
            return `${helpers
              .getEnumValue(node.data.status)
              ?.toLocaleLowerCase()}` == 'available'
              ? 'fas fa-eye-slash'
              : 'fas fa-eye'
          },
          enable: true,
          callback: ({ node }) => {
            this.onChangeBookStatusByIdHandler(node.data.id)
          }
        },
        {
          title: this.$t(
            'controlTower.pages.takeUpBook.messages.generateProducerMonitoring'
          ),
          className: 'fas fa-desktop',
          enable: ({ node }) => {
            return (
              `${helpers
                .getEnumValue(node.data.status)
                ?.toLocaleLowerCase()}` == 'available'
            )
          },
          callback: ({ node }) => {
            this.onGenerateProducerMonitoringByIdHandler(node.data.id)
          }
        }
      ]
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey: 'controlTower.pages.takeUpBook.messages.makeAvailable',
          onClick: this.onChangeBookStatusHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-eye'
        },
        {
          canShow: true,
          titleKey:
            'controlTower.pages.takeUpBook.messages.generateProducerMonitoring',
          onClick: this.onGenerateProducerMonitoringHandler,
          iconColor: '#959DB5',
          faIcon: 'fa-desktop'
        }
      ]
      return meta
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    hasErrors(response) {
      return response.error
    },
    async confirmGenerateProducerMonitoringByFilterCompleted() {
      let confirm = await this.confirmGenerateProducerMonitoringByFilter()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm =
          await this.confirmGenerateProducerMonitoringByFilterOnAllItems()
      }
      return confirm
    },
    async confirmGenerateProducerMonitoringByFilter() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpBook.messages.confirmGenerateProducerMonitoringByFilter'
        )
      })
    },
    async confirmGenerateProducerMonitoringByFilterOnAllItems() {
      return helpers.createConfirmPromise(
        this,
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpBook.messages.confirmGenerateProducerMonitoringByFilterOnAllItems'
          )
        },
        () => service.hasFilterApplied == null || service.hasFilterApplied()
      )
    },
    async onGenerateProducerMonitoringHandler() {
      const confirm =
        await this.confirmGenerateProducerMonitoringByFilterCompleted()
      if (confirm) {
        try {
          await this.generateProducerMonitoringByFilter()
        } catch (err) {
          console.error(err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async generateProducerMonitoringByFilter() {
      const resp = await service.generateProducerMonitoringByFilter()
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    async onGenerateProducerMonitoringByIdHandler(id) {
      const confirm = await helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$tc(
          'controlTower.pages.takeUpBook.messages.confirmGenerateProducerMonitoring'
        )
      })
      if (confirm) {
        try {
          await this.generateProducerMonitoringById(id)
        } catch (err) {
          this.notifyErrors(err)
          console.error('Failed to change book status', err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async generateProducerMonitoringById(id) {
      const resp = await service.generateProducerMonitoringById(id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    async confirmChangeBookStatusByFilterCompleted() {
      let confirm = await this.confirmChangeBookStatusByFilter()
      if (confirm) {
        await new Promise((x) => setTimeout(x))
        confirm = await this.confirmChangeBookStatusByFilterOnAllItems()
      }
      return confirm
    },
    async confirmChangeBookStatusByFilter() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpBook.messages.confirmChangeBookStatusByFilter'
        )
      })
    },
    async confirmChangeBookStatusByFilterOnAllItems() {
      return helpers.createConfirmPromise(
        this,
        {
          type: 'error',
          title: this.$t('core.crud.attention'),
          subtitle: this.$t(
            'controlTower.pages.takeUpBook.messages.confirmChangeBookStatusByFilterOnAllItems'
          )
        },
        () => service.hasFilterApplied == null || service.hasFilterApplied()
      )
    },
    async onChangeBookStatusHandler() {
      const confirm = await this.confirmChangeBookStatusByFilterCompleted()
      if (confirm) {
        try {
          await this.changeBookStatus()
        } catch (err) {
          console.error('Failed to change book status', err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async changeBookStatus() {
      const resp = await service.changeBookStatus()
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    async onChangeBookStatusByIdHandler(id) {
      const confirm = await helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$tc(
          'controlTower.pages.takeUpBook.messages.confirmChangeBookStatus'
        )
      })
      if (confirm) {
        try {
          await this.changeBookStatusById(id)
        } catch (err) {
          this.notifyErrors(err)
          console.error('Failed to change book status', err)
          this.notify.error({
            title: this.$tc('core.misc.error')
          })
        }
      }
    },
    async changeBookStatusById(id) {
      const resp = await service.changeBookStatusById(id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
