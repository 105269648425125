import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/controltower/takeUpBook', api, {
  importLogUrl: '/api/controltower/importLog'
})

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.brainOption.options = ctx.brainOptionOptions
}

service.changeBookStatusById = async (id) => {
  try {
    const response = await api.put(
      `/api/controltower/takeUpBook/changeBookStatusById/${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.changeBookStatus = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.post(
      `/api/controltower/takeUpBook/changeBookStatus${filter}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.generateProducerMonitoringById = async (id) => {
  try {
    const response = await api.put(
      `/api/controltower/takeUpBook/generateProducerMonitoring/${id}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

service.generateProducerMonitoringByFilter = async () => {
  try {
    const filter = _.isEmpty(service.lastAppliedFilter)
      ? ''
      : `?${service.lastAppliedFilter}`
    const response = await api.post(
      `/api/controltower/takeUpBook/generateProducerMonitoringByFilter${filter}`
    )
    return response?.data
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export default service
